import React from "react"

interface HeaderProps {
	title: string
	description: string
}

const Header = (props: HeaderProps) => {
	return (
		<div className="w-[100%] bg-tofuDarkGrey  h-[60vh] md:h-[70vh] relative justify-center flex flex-col">
			<div className="text-tofuLightWhite mt-44 md:mt-64 text-2xl md:text-5xl lg:text-6xl mx-auto font-syne font-extrabold hover:text-tofuGreen ">
				{props.title}
			</div>
			<div className="mt-2 lg:mb-10 text-tofuLightWhite w-[45%] md:w-auto mx-auto text-center text-base 2xl:text-lg font-syne">
				{props.description}
			</div>
			<div className="w-[18%] md:w-[26.5%] bg-tofuLightGrey h-[8vh] md:h-[13vh] absolute bottom-0 left-0"></div>
			<div className="w-[18%] md:w-[26.5%] bg-tofuLightGrey h-[8vh] md:h-[13vh] absolute bottom-0 right-0"></div>
		</div>
	)
}

export default Header
