import { Link } from "gatsby"
import { Props } from "google-map-react"
import React, { FC, PropsWithChildren } from "react"
import { useState } from "react"
import useMediaQuery from "../../hooks/useMediaQuery"
import { useTranslation } from "../../i18n"
import i18n from "i18next"

interface WorkItemProps {
	Title: string
	Location: string
	Color: string
	Img: string
	TitleFontSize?: string
	LocationFontSize?: string
	Scale?: string
	LinkTo?: string
}

const WorkItem = ({
	Title,
	Location,
	Color,
	Img,
	TitleFontSize,
	LocationFontSize,
	Scale,
	LinkTo,
}: WorkItemProps) => {
	const [colorState, setColorState] = useState<boolean>(false)

	const [photoZoom, setPhotoZoom] = useState<string>("")
	const { t } = useTranslation()
	const handleZoom = () => {
		if (photoZoom === "") {
			setPhotoZoom("scale-105")
		} else {
			setPhotoZoom("")
		}
		setColorState((old) => !old)
	}

	const isDesktop = useMediaQuery("(min-width: 1024px)")

	if (isDesktop) {
		return (
			<Container link={LinkTo} scale={Scale} handleZoom={handleZoom}>
				<div className="w-[100%] h-[70%]  overflow-hidden items-center flex flex-col relative">
					<img
						src={Img}
						className={`${photoZoom} ${
							TitleFontSize || ""
						}   object-cover  duration-300 w-[100%] h-[100%] `}
					/>
				</div>
				<div className={`h-[20%] lg:h-[32%] xl:h-[32%] 2xl:h-[29%]`}>
					<div
						className={`w-full ${Color} ${
							colorState ? "h-full" : "h-0"
						} transition-[height] duration-300`}
					>
						<div className="absolute mt-[2%] ml-[3%] w-[70%] lg:w-[45%]">
							<div className="font-syne font-bold min-h-[8vh] lg:min-h-[10vh] leading-none flex self-center text-md sm:text-xl md:text-2xl lg:text-xl xl:text-2xl 2xl:text-3xl">
								{Title}
							</div>
							<div className="font-syne font-normal w-auto lg:text-md xl:text-xl xl:mt-4  3xl:text-2xl  3xl:mt-12  ">
								<div className="hidden lg:flex flex-row   ">
									<div className={` ${LocationFontSize}`}>
										<span className="font-syne font-bold">{t("location_works")} </span>
										<span className="ml-1 font-syne font-normal ">
											{" "}
											{Location}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Container>
		)
	} else {
		return (
			<LinkOrNot to={LinkTo} >
				<div className="w-[94%] mx-auto mb-4">
					<div className="h-[70%]  overflow-hidden items-center flex flex-col relative">
						<img
							src={Img}
							className={`${photoZoom} ${
								TitleFontSize || ""
							}   object-cover scale-110 duration-300 w-[100%] h-[100%] aspect-[16/9] `}
						/>
					</div>
					<div
						className={`${Color} flex items-center h-full duration-300 leading-tight`}
					>
						<div className="p-6 h-full w-full lg:w-[45%] text-lg leading-tight font-syne font-bold text-forceLightWhite flex items-center">
							{Title}
						</div>
					</div>
				</div>
			</LinkOrNot>
		)
	}
}

export default WorkItem

const Container: FC<
	PropsWithChildren<{ link?: string; scale?: string; handleZoom: () => void }>
> = ({ link, children, handleZoom, scale }) => {
	if (link)
		return (
			<div
				className={`  ${
					scale || "w-[80%] lg:w-[49.5%]"
				}  aspect-[10/9] lg:aspect-[7/6] flex-col justify-center lg:mt-16 text-tofuLightWhite hover:text-forceLightWhite`}
				onMouseEnter={handleZoom}
				onMouseLeave={handleZoom}
			>
				<Link hrefLang={i18n.language === "en" ? "en" : "jp"}  to={link}>{children}</Link>
			</div>
		)

	return (
		<div
			className={`  ${
				scale || "w-[80%] lg:w-[49.5%]"
			}  aspect-[10/9]  lg:aspect-[7/6] flex-col justify-center lg:mt-16 text-tofuLightWhite hover:text-forceLightWhite`}
			onMouseEnter={handleZoom}
			onMouseLeave={handleZoom}
		>
			{children}
		</div>
	)
}

export const LinkOrNot: React.FC<
	PropsWithChildren<{ to?: string; className?: string }>
> = ({ to, children, className }) => {
	if (to) {
		return (
			<Link hrefLang={i18n.language === "en" ? "en" : "jp"}className={`block w-full h-fit ${className}`} to={to}>
				{children}
			</Link>
		)
	} else {
		return <>{children}</>
	}
}
